// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-about-us-page-js": () => import("./../src/templates/about-us-page.js" /* webpackChunkName: "component---src-templates-about-us-page-js" */),
  "component---src-templates-asset-management-page-js": () => import("./../src/templates/asset-management-page.js" /* webpackChunkName: "component---src-templates-asset-management-page-js" */),
  "component---src-templates-careers-page-js": () => import("./../src/templates/careers-page.js" /* webpackChunkName: "component---src-templates-careers-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-custom-homes-page-js": () => import("./../src/templates/custom-homes-page.js" /* webpackChunkName: "component---src-templates-custom-homes-page-js" */),
  "component---src-templates-footer-js": () => import("./../src/templates/footer.js" /* webpackChunkName: "component---src-templates-footer-js" */),
  "component---src-templates-header-js": () => import("./../src/templates/header.js" /* webpackChunkName: "component---src-templates-header-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-jp-about-us-page-jp-js": () => import("./../src/templates/jp/about-us-page-jp.js" /* webpackChunkName: "component---src-templates-jp-about-us-page-jp-js" */),
  "component---src-templates-jp-asset-management-page-jp-js": () => import("./../src/templates/jp/asset-management-page-jp.js" /* webpackChunkName: "component---src-templates-jp-asset-management-page-jp-js" */),
  "component---src-templates-jp-careers-page-jp-js": () => import("./../src/templates/jp/careers-page-jp.js" /* webpackChunkName: "component---src-templates-jp-careers-page-jp-js" */),
  "component---src-templates-jp-contact-page-jp-js": () => import("./../src/templates/jp/contact-page-jp.js" /* webpackChunkName: "component---src-templates-jp-contact-page-jp-js" */),
  "component---src-templates-jp-custom-homes-page-jp-js": () => import("./../src/templates/jp/custom-homes-page-jp.js" /* webpackChunkName: "component---src-templates-jp-custom-homes-page-jp-js" */),
  "component---src-templates-jp-index-page-jp-js": () => import("./../src/templates/jp/index-page-jp.js" /* webpackChunkName: "component---src-templates-jp-index-page-jp-js" */),
  "component---src-templates-jp-news-page-jp-js": () => import("./../src/templates/jp/news-page-jp.js" /* webpackChunkName: "component---src-templates-jp-news-page-jp-js" */),
  "component---src-templates-jp-news-post-jp-js": () => import("./../src/templates/jp/news-post-jp.js" /* webpackChunkName: "component---src-templates-jp-news-post-jp-js" */),
  "component---src-templates-jp-portfolio-page-jp-js": () => import("./../src/templates/jp/portfolio-page-jp.js" /* webpackChunkName: "component---src-templates-jp-portfolio-page-jp-js" */),
  "component---src-templates-jp-privacy-page-jp-js": () => import("./../src/templates/jp/privacy-page-jp.js" /* webpackChunkName: "component---src-templates-jp-privacy-page-jp-js" */),
  "component---src-templates-jp-project-management-page-jp-js": () => import("./../src/templates/jp/project-management-page-jp.js" /* webpackChunkName: "component---src-templates-jp-project-management-page-jp-js" */),
  "component---src-templates-jp-property-listing-jp-js": () => import("./../src/templates/jp/property-listing-jp.js" /* webpackChunkName: "component---src-templates-jp-property-listing-jp-js" */),
  "component---src-templates-jp-terms-page-jp-js": () => import("./../src/templates/jp/terms-page-jp.js" /* webpackChunkName: "component---src-templates-jp-terms-page-jp-js" */),
  "component---src-templates-news-page-js": () => import("./../src/templates/news-page.js" /* webpackChunkName: "component---src-templates-news-page-js" */),
  "component---src-templates-news-post-js": () => import("./../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-portfolio-order-js": () => import("./../src/templates/portfolio-order.js" /* webpackChunkName: "component---src-templates-portfolio-order-js" */),
  "component---src-templates-portfolio-page-js": () => import("./../src/templates/portfolio-page.js" /* webpackChunkName: "component---src-templates-portfolio-page-js" */),
  "component---src-templates-privacy-page-js": () => import("./../src/templates/privacy-page.js" /* webpackChunkName: "component---src-templates-privacy-page-js" */),
  "component---src-templates-project-management-page-js": () => import("./../src/templates/project-management-page.js" /* webpackChunkName: "component---src-templates-project-management-page-js" */),
  "component---src-templates-property-listing-js": () => import("./../src/templates/property-listing.js" /* webpackChunkName: "component---src-templates-property-listing-js" */),
  "component---src-templates-settings-js": () => import("./../src/templates/settings.js" /* webpackChunkName: "component---src-templates-settings-js" */),
  "component---src-templates-terms-page-js": () => import("./../src/templates/terms-page.js" /* webpackChunkName: "component---src-templates-terms-page-js" */),
  "component---src-templates-testimonials-page-js": () => import("./../src/templates/testimonials-page.js" /* webpackChunkName: "component---src-templates-testimonials-page-js" */),
  "component---src-templates-zhhans-about-us-page-zhhans-js": () => import("./../src/templates/zhhans/about-us-page-zhhans.js" /* webpackChunkName: "component---src-templates-zhhans-about-us-page-zhhans-js" */),
  "component---src-templates-zhhans-asset-management-page-zhhans-js": () => import("./../src/templates/zhhans/asset-management-page-zhhans.js" /* webpackChunkName: "component---src-templates-zhhans-asset-management-page-zhhans-js" */),
  "component---src-templates-zhhans-careers-page-zhhans-js": () => import("./../src/templates/zhhans/careers-page-zhhans.js" /* webpackChunkName: "component---src-templates-zhhans-careers-page-zhhans-js" */),
  "component---src-templates-zhhans-contact-page-zhhans-js": () => import("./../src/templates/zhhans/contact-page-zhhans.js" /* webpackChunkName: "component---src-templates-zhhans-contact-page-zhhans-js" */),
  "component---src-templates-zhhans-custom-homes-page-zhhans-js": () => import("./../src/templates/zhhans/custom-homes-page-zhhans.js" /* webpackChunkName: "component---src-templates-zhhans-custom-homes-page-zhhans-js" */),
  "component---src-templates-zhhans-index-page-zhhans-js": () => import("./../src/templates/zhhans/index-page-zhhans.js" /* webpackChunkName: "component---src-templates-zhhans-index-page-zhhans-js" */),
  "component---src-templates-zhhans-news-page-zhhans-js": () => import("./../src/templates/zhhans/news-page-zhhans.js" /* webpackChunkName: "component---src-templates-zhhans-news-page-zhhans-js" */),
  "component---src-templates-zhhans-privacy-page-zhhans-js": () => import("./../src/templates/zhhans/privacy-page-zhhans.js" /* webpackChunkName: "component---src-templates-zhhans-privacy-page-zhhans-js" */),
  "component---src-templates-zhhans-project-management-page-zhhans-js": () => import("./../src/templates/zhhans/project-management-page-zhhans.js" /* webpackChunkName: "component---src-templates-zhhans-project-management-page-zhhans-js" */),
  "component---src-templates-zhhans-property-listing-zhhans-js": () => import("./../src/templates/zhhans/property-listing-zhhans.js" /* webpackChunkName: "component---src-templates-zhhans-property-listing-zhhans-js" */),
  "component---src-templates-zhhans-terms-page-zhhans-js": () => import("./../src/templates/zhhans/terms-page-zhhans.js" /* webpackChunkName: "component---src-templates-zhhans-terms-page-zhhans-js" */)
}

